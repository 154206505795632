import React from 'react'
import './amenities.css'
import { images } from '../../constants'
import data from '../../constants/data'

const AmenitiesCard = ({hotmelts : {imgUrl , title}}) => (
    <div className="app__amenities_img-container">
        <img src={imgUrl} alt="" />
        <p>{title}</p>
    </div>
)

const Amenities = () => {
  return (
    <div className="app__amenities">
        <div className="app__heading">We Provide 25+ Free Amenities</div>
        <div className="app__amenities_img">
            {
                data.allamenities.map((hotmelts) => <AmenitiesCard hotmelts={hotmelts} key={hotmelts.title}/>)
            }
        </div>
    </div>
  )
}

export default Amenities