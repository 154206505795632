import React from 'react'
import './kitchen.css'
// import { images } from '../../constants'
import data from '../../constants/data'

const KitchenCard = ({items : {imgUrl , title}}) => (
    <div className="app__amenities_img-container">
        <img src={imgUrl} alt="" />
        <p>{title}</p>
    </div>
)

const Kitchen = () => {
  return (
    <div className="app__amenities">
        <div className="app__heading">Kitchen Amenities</div>
        <div className="app__amenities_img">
            {
                data.kitchen.map((item) => <KitchenCard items={item} key={item.title}/>)
            }
        </div>
    </div>
  )
}

export default Kitchen