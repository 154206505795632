import React from 'react'
import Carousel from 'better-react-carousel'
import {images} from '../../constants'
import './gallery.css'

const Gallery = () => {
    return (
        <div className="app__gallery section__padding" id='gallery' >
            <div className="app__heading" style={{textAlign:'center'}}>Our Gallery</div>
            {/* <div className="app__para" style={{textAlign:'center', marginBottom:'10px'}}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ab magnam quia harum voluptas ratione hic eos consequatur amet modi suscipit nostrum voluptatibus, excepturi ipsum dolore culpa inventore quo dicta vel?</div> */}
            <Carousel cols={3} rows={1} gap={10} loop>
                {/* <Carousel.Item>
                    <img width="100%" src={images.room1} />
                </Carousel.Item> */}
                <Carousel.Item>
                    <img width="100%" src={images.room2} />
                </Carousel.Item>
                <Carousel.Item>
                    <img width="100%" src={images.room3}/>
                </Carousel.Item>
                <Carousel.Item>
                    <img width="100%" src={images.room4} />
                </Carousel.Item>
                <Carousel.Item>
                    <img width="100%" src={images.room5} />
                </Carousel.Item>
                <Carousel.Item>
                    <img width="100%" src={images.room6} />
                </Carousel.Item>
                <Carousel.Item>
                    <img width="100%" src={images.room7} />
                </Carousel.Item>
                <Carousel.Item>
                    <img width="100%" src={images.room8} />
                </Carousel.Item>
                <Carousel.Item>
                    <img width="100%" src={images.room9} />
                </Carousel.Item>
                <Carousel.Item>
                    <img width="100%" src={images.room9} />
                </Carousel.Item>
                <Carousel.Item>
                    <img width="100%" src={images.room9} />
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Gallery