import React from 'react'
import './homepage.css'
import images from '../../constants/images'
import bg from '../../assets/bg.png'
import { Button } from '../../components'
// import { MdPhoneIphone, MdMail, MdLocationPin } from 'react-icons/md'
import ImageCarousel from '../ImageCarousel'
import  whatsapp from '../../assets/whatsapp.png'

const HomePage = () => {
    return (
        <div className="app__homepage app__wrapper section__padding ">
            <div className="app__wrapper_info">
                <div className="app__subheading">
                    Welcome to Friendly Homes
                </div>
                <div className="app__heading">
                Come with bare hands and get all the amenities
                </div>
                <div className="app__para">
                    Ever dreamt of finding a luxury co-living | PG | Guest House space in Hyderabad with low cost? Well, the search is over.
                    We provide almost 35 plus amenities like TV, Sofa, Refridgerator, AC, Geyser, Kitchen Items and more more more...., with high quality at very low price.
                </div>
                <div >
                    <Button text='Contact Us' />
                </div>

                <a href="https://wa.me/919110524001" className="whatsapp" target='-blank'>
                    <img src={whatsapp} alt="" />
                </a>

            </div>
        </div>
    )
}

export default HomePage