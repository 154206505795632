import React from 'react'
import './bedroom.css'
import data from '../../constants/data'

const BedroomCard = ({items : {imgUrl , title}}) => (
    <div className="app__amenities_img-container">
        <img src={imgUrl} alt="" />
        <p>{title}</p>
    </div>
)

const Bedroom = () => {
  return (
    <div className="app__amenities">
        <div className="app__heading">Bedroom Amenities</div>
        <div className="app__amenities_img">
            {
                data.bedroom.map((item) => <BedroomCard items={item} key={item.title}/>)
            }
        </div>
    </div>
  )
}

export default Bedroom