import React from 'react'
import './index.css'
import './App.css'
import { Navbar } from './components'
import { Amenities, Bedroom, Gallery, HomePage, Kitchen } from './container'

const App = () => {
  return (
    <div>
      <Navbar />
      <HomePage />
      <Amenities />
      <Kitchen />
      <Bedroom />
      <Gallery />
    </div>
  )
}

export default App