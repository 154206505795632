import images from './images'

const amenities = [
    {
        imgUrl : images.a1, title : 'CC Camera'
    },
    {
        imgUrl : images.a2, title : 'Cupboard'
    },
    {
        imgUrl : images.a3, title : 'Lounge'
    },
    {
        imgUrl : images.a4, title : 'Side Table'
    },
    {
        imgUrl : images.a5, title : 'AC'
    },
    {
        imgUrl : images.a6, title : 'Bike parking'
    },
    {
        imgUrl : images.a7, title : 'Car parking'
    },
    // {
    //     imgUrl : images.a8, title : 'Washing Machine'
    // },
    {
        imgUrl : images.a9, title : 'Housekeeping'
    },
    {
        imgUrl : images.a10, title : 'Kitchen'
    },
    {
        imgUrl : images.a11, title : 'Lift'
    },
    {
        imgUrl : images.a12, title : 'Microwave'
    },
    {
        imgUrl : images.a13, title : 'Pet'
    },
    {
        imgUrl : images.a14, title : 'Reception'
    },
    {
        imgUrl : images.a15, title : 'Refrigerator'
    },
    {
        imgUrl : images.a16, title : 'Sanitization'
    },
    {
        imgUrl : images.a17, title : 'Security'
    },
    {
        imgUrl : images.a19, title : 'Gas'
    },
    {
        imgUrl : images.a20, title : 'Tv'
    },
    {
        imgUrl : images.a21, title : 'Washing Machine'
    },
    {
        imgUrl : images.a22, title : 'Geyser'
    },
    {
        imgUrl : images.a23, title : 'Mineral Water'
    },
    {
        imgUrl : images.a24, title : 'Wifi'
    },
    {
        imgUrl : images.a25, title : 'Work Desk'
    },
    {
        imgUrl : images.a25, title : 'Power Backup'
    },
    {
        imgUrl : images.a25, title : 'Veg & Non-Veg'
    }
]


const kitchen = [
    {
        imgUrl : images.k1, title : 'Rice Cooker'
    },
    {
        imgUrl : images.k2, title : 'Mixer'
    },
    {
        imgUrl : images.k3, title : 'Gas Cylinder'
    },
    {
        imgUrl : images.k4, title : 'Curry Bowl'
    },
    {
        imgUrl : images.k5, title : 'Dosa Pan'
    },
    {
        imgUrl : images.k6, title : 'Gas Stove'
    }
    ,    {
        imgUrl : images.k7, title : 'Fry Pan'
    }
    ,    {
        imgUrl : images.k8, title : 'Milk Bowl'
    },
    {
        imgUrl : images.k9, title : 'Micro Oven'
    },
    {
        imgUrl : images.k10, title : 'Mineral Water'
    }
]


const bedroom = [
    {
        imgUrl : images.b1, title : 'Bed'
    },
    {
        imgUrl : images.b2, title : 'Blanket'
    },
    {
        imgUrl : images.b3, title : 'Ac'
    },
    {
        imgUrl : images.b4, title : 'Pillow'
    },
    {
        imgUrl : images.b5, title : 'Fan'
    },
    {
        imgUrl : images.b6, title : 'Mirror'
    }
    ,    {
        imgUrl : images.b7, title : 'Window Curtains'
    }
    ,    {
        imgUrl : images.b8, title : 'Cupboard'
    },
    {
        imgUrl : images.b9, title : '2 Charging Bulbs'
    }
]

const allamenities = [
    {
        imgUrl : images.AA1, title : 'CC Cam'
    },
    {
        imgUrl : images.AA2, title : 'Wall Clock'
    },
    {
        imgUrl : images.AA3, title : 'Laundry Basket'
    },
    {
        imgUrl : images.AA4, title : 'Cloth Drying Stand'
    },
    {
        imgUrl : images.AA5, title : 'Door Curtains'
    },
    {
        imgUrl : images.AA6, title : 'Door Mats'
    },
    {
        imgUrl : images.AA7, title : 'Geyser'
    },
    {
        imgUrl : images.AA8, title : 'Hanger'
    },
    {
        imgUrl : images.AA9, title : 'House Keeping'
    },
    {
        imgUrl : images.AA10, title : 'Lift'
    },
    {
        imgUrl : images.AA11, title : 'Lock Set'
    },
    {
        imgUrl : images.AA12, title : 'Parking'
    },
    {
        imgUrl : images.AA13, title : 'Reception'
    },
    {
        imgUrl : images.AA14, title : 'Sanitization'
    },
    {
        imgUrl : images.AA15, title : 'Security'
    },
    {
        imgUrl : images.AA16, title : 'WiFi'
    },
    {
        imgUrl : images.AA17, title : 'Shoe Stand'
    },
    {
        imgUrl : images.AA18, title : 'Smart Tv'
    },
    {
        imgUrl : images.AA19, title : 'Sofa'
    },
    {
        imgUrl : images.AA20, title : 'Washing Machine'
    },
    {
        imgUrl : images.AA21, title : 'Work desk'
    }
]

export default {
    amenities, kitchen, bedroom, allamenities
}