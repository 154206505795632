import logo from '../assets/logo.png'
import logowhite from '../assets/logowhite.png'
import bg from '../assets/bg.png'

// Amenities 
import a1 from '../assets/Amenities/CCTV1.png'
import a2 from '../assets/Amenities/Cupboard1.png'
import a3 from '../assets/Amenities/Lounge1.png'
import a4 from '../assets/Amenities/Side Table1.png'
import a5 from '../assets/Amenities/ac1.png'
import a6 from '../assets/Amenities/bike parking1.png'
import a7 from '../assets/Amenities/car parking1.png'
// import a8 from '../assets/Amenities/car.png'
import a9 from '../assets/Amenities/housekeeping1.png'
import a10 from '../assets/Amenities/kitchen1.png'
import a11 from '../assets/Amenities/lift1.png'
import a12 from '../assets/Amenities/microwave1.png'
import a13 from '../assets/Amenities/pet1.png'
import a14 from '../assets/Amenities/reception1.png'
import a15 from '../assets/Amenities/refrigerator1.png'
import a16 from '../assets/Amenities/sanitization1.png'
import a17 from '../assets/Amenities/security1.png'
// import a18 from '../assets/Amenities/security.png'
import a19 from '../assets/Amenities/stove-icon1.png'
import a20 from '../assets/Amenities/tv1.png'
import a21 from '../assets/Amenities/washing-machine-icon-4-removebg-preview1.png'
import a22 from '../assets/Amenities/water-heater1.png'
import a23 from '../assets/Amenities/water-icon-png-19.jpg'
import a24 from '../assets/Amenities/wifi1.png'
import a25 from '../assets/Amenities/workdesk1.png'

// Room 
import room1 from '../assets/Room/1.jpeg'
import room2 from '../assets/Room/2.jpg'
import room3 from '../assets/Room/3.jpg'
import room4 from '../assets/Room/4.jpg'
import room5 from '../assets/Room/5.jpg'
import room6 from '../assets/Room/6.jpg'
import room7 from '../assets/Room/7.jpg'
import room8 from '../assets/Room/8.jpg'
import room9 from '../assets/Room/9.jpg'
import room10 from '../assets/Room/10.jpg'
import room11 from '../assets/Room/11.jpg'


// Kitchen 

import k1 from '../assets/kitchen/ricecooker.png'
import k2 from '../assets/kitchen/mixer.png'
import k3 from '../assets/kitchen/gascylinder.png'
import k4 from '../assets/kitchen/currybowl.png'
import k5 from '../assets/kitchen/dosapan.png'
import k6 from '../assets/kitchen/gast stove.png'
import k7 from '../assets/kitchen/frypan.png'
import k8 from '../assets/kitchen/milkbowl.png'
import k9 from '../assets/kitchen/microwave.png'
import k10 from '../assets/kitchen/mineralwater.png'


// bedroom 

import b1 from '../assets/Bedroom/bed.png'
import b2 from '../assets/Bedroom/blanket.png'
import b3 from '../assets/Bedroom/ac.png'
import b4 from '../assets/Bedroom/pillow.png'
import b5 from '../assets/Bedroom/fan.png'
import b6 from '../assets/Bedroom/mirror.png'
import b7 from '../assets/Bedroom/window curtains.png'
import b8 from '../assets/Bedroom/cupboard.png'
import b9 from '../assets/Bedroom/charging bulb.png'


// All Amenities 
import AA1 from '../assets/AllAmenities/cc cam.png'
import AA2 from '../assets/AllAmenities/clock.png'
import AA3 from '../assets/AllAmenities/cloth basket.png'
import AA4 from '../assets/AllAmenities/cloth drying stand.png'
import AA5 from '../assets/AllAmenities/door curtains.png'
import AA6 from '../assets/AllAmenities/doormat.png'
import AA7 from '../assets/AllAmenities/geyser.png'
import AA8 from '../assets/AllAmenities/hangers.png'
import AA9 from '../assets/AllAmenities/house keeping.png'
import AA10 from '../assets/AllAmenities/lift.png'
import AA11 from '../assets/AllAmenities/lock set.png'
import AA12 from '../assets/AllAmenities/parking.png'
import AA13 from '../assets/AllAmenities/reception.png'
import AA14 from '../assets/AllAmenities/sanitization.png'
import AA15 from '../assets/AllAmenities/security.png'
import AA16 from '../assets/AllAmenities/wifi.png'
import AA17 from '../assets/AllAmenities/shoe stand.png'
import AA18 from '../assets/AllAmenities/smart tv.png'
import AA19 from '../assets/AllAmenities/sofa.png'
import AA20 from '../assets/AllAmenities/washing machine.png'
import AA21 from '../assets/AllAmenities/work table.png'


export default {
    logo, logowhite, bg, a1, a2, a3, a4, a5, a6, a7, a9, a10, a11, a12, a13, a14, a15, a16, a17,  a19, a20, a21, a22, a23, a24, a25, room1,room2, room3, room4, room5, room6, room7, room8, room9, room10, room11, k1, k2, k3, k4, k5, k6, k7 ,k8, k9, k10,b1, b2, b3, b4, b5, b6, b7, b8, b9, AA1, AA2, AA3, AA4, AA5, AA6, AA7, AA8, AA9, AA10, AA11, AA12, AA13, AA14, AA15, AA16, AA17, AA18, AA19, AA20, AA21
}